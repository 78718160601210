<template>
  <InnerScroll :title="$t('system.navigation.counselingEvents')">
    <router-view></router-view>
  </InnerScroll>
</template>

<script>
import InnerScroll from '../InnerScroll/InnerScroll.vue'
export default {
  name: 'CounselingEvent',
  components: {
    InnerScroll
  }
}
</script>
